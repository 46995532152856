
















































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import EditDonationIncome from "@/views/project/donationIncome/edit.vue";
import {
  CapitalIncomeRecordFromDonationDto,
  AuditFlowScope,
} from "@/api/appService";
import ImportExcel from "@/components/ImportExcel/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "donationIncomeList",
  components: {
    PagedTableView,
    AbSelect,
    EditDonationIncome,
    ImportExcel,
    ExportButton,
    AgileAuditTag,
  },
})
export default class DonationIncomeList extends Vue {
  @Ref() editForm!: EditDonationIncome;

  tableItems: CapitalIncomeRecordFromDonationDto[] = [];
  private currentDetail: CapitalIncomeRecordFromDonationDto = {};
  queryForm: any = {
    bankAccount: undefined,
    donatorName: undefined,
    totalIncomeMoney: undefined,
    totalIncomeMoneyStart: undefined,
    totalIncomeMoneyEnd: undefined,
    incomeDate: undefined,
    incomeDateStart: undefined,
    incomeDateEnd: undefined,
    incomeType: undefined,
    capitalProperty: undefined,
    currencyType: undefined,
  };
  editDonationIncomeId = 0;
  showTotalMoney = 1;
  totalMoney = 0;

  payTypeList = [
    {
      value: "Cash",
      label: "现金",
    },
    {
      value: "Transfer",
      label: "转账",
    },
  ];
  capitalPropertyList = [
    {
      value: "NonFinite",
      label: "非限定性",
    },
    {
      value: "Finite",
      label: "限定性",
    },
  ];

  currencyTypeList = [
    {
      value: "RMB",
      label: "人民币",
    },
  ];

  fetchData(params: any) {
    this.getTotalMoney();
    return api.capitalIncomeRecordFromDonation.getAll(params);
  }

  // 合计金额
  getTotalMoney() {
    api.capitalIncomeRecordFromDonation
      .getDonationTotalMoney(this.queryForm)
      .then((res) => {
        this.totalMoney = res;
      });
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.editDonationIncomeId = 0;
    (this.$refs.editForm as any).show = true;
  }

  handleEdit(index: number, row: any) {
    this.editDonationIncomeId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  handleViewDetail(row: CapitalIncomeRecordFromDonationDto) {
    this.currentDetail = row;
    (this.$refs.donationIncomeDetail as any).show = true;
  }
  handleDetail(row: any) {
    this.$router.push({
      name: "donationIncome-detail",
      params: {
        id: row.id,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: CapitalIncomeRecordFromDonationDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.capitalIncomeRecordFromDonation
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  async handleStartAudit(
    index: number,
    row: CapitalIncomeRecordFromDonationDto
  ) {
    // await api.capitalIncomeRecordFromDonation
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.CapitalIncomeRecordFromDonation,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
