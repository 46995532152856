import {
  ActivityApplyStatus,
  CapitalProperty,
  CurrencyType,
  IncomeCategory,
  PayType,
} from "@/api/appService";

export function getCapitalPropertyList() {
  return [
    {
      label: "非限定性",
      value: CapitalProperty.NonFinite,
    },
    {
      label: "限定性",
      value: CapitalProperty.Finite,
    },
  ];
}

export function getCurrencyType() {
  return [
    {
      value: CurrencyType.RMB,
      label: "人民币",
    },
  ];
}

export function getPayTypes() {
  return [
    {
      value: PayType.Cash,
      label: "现金",
    },
    {
      value: PayType.Transfer,
      label: "转账",
    },
  ];
}

export function getIncomeCategory() {
  return [
    {
      label: "利息",
      value: IncomeCategory.Interest,
    },
    {
      label: "投资收益",
      value: IncomeCategory.Investment,
    },
    {
      label: "其他",
      value: IncomeCategory.Other,
    },
  ];
}

export function getActivityApplyStatus() {
  return [
    {
      value: ActivityApplyStatus.Auditing,
      label: "待审核",
    },
    {
      value: ActivityApplyStatus.Return,
      label: "已退回",
    },
    {
      value: ActivityApplyStatus.Approved,
      label: "已通过",
    },
    {
      value: ActivityApplyStatus.Finished,
      label: "已完成",
    },
    {
      value: ActivityApplyStatus.Cancel,
      label: "已取消",
    },
  ];
}
